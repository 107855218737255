import React, { useState } from 'react';
import { FaPlus, FaSearch, FaEdit, FaTrash, FaCopy, FaTimes } from 'react-icons/fa';

const Nips = ({ nips, addNip, editNip, deleteNip }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [showAddNip, setShowAddNip] = useState(false);
  const [newNip, setNewNip] = useState({ localName: '', localNip: '' });
  const [editingNip, setEditingNip] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [copyNotification, setCopyNotification] = useState(false);
  const [errors, setErrors] = useState({});

  const filteredNips = nips.filter(nip =>
    nip.localName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    nip.localNip.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleAddNip = () => {
    const newErrors = {};
    if (!newNip.localName.trim()) newErrors.localName = "Nome do local é obrigatório";
    if (!newNip.localNip.trim()) newErrors.localNip = "Número do NIP é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    addNip(newNip);
    setNewNip({ localName: '', localNip: '' });
    setShowAddNip(false);
    setErrors({});
  };

  const handleEditClick = (nip) => {
    setEditingNip({ ...nip });
  };

  const handleSaveEdit = () => {
    const newErrors = {};
    if (!editingNip.localName.trim()) newErrors.localName = "Nome do local é obrigatório";
    if (!editingNip.localNip.trim()) newErrors.localNip = "Número do NIP é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    editNip(editingNip.id, editingNip);
    setEditingNip(null);
    setErrors({});
  };

  const handleDeleteNip = (id, localName) => {
    setConfirmDelete({ id, localName });
  };

  const confirmDeleteNip = () => {
    if (confirmDelete) {
      deleteNip(confirmDelete.id);
      setConfirmDelete(null);
    }
  };

  const handleLocalNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setNewNip({ ...newNip, localName: value });
    }
  };

  const handleLocalNipChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setNewNip({ ...newNip, localNip: value });
    }
  };

  const handleEditLocalNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setEditingNip({ ...editingNip, localName: value });
    }
  };

  const handleEditLocalNipChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setEditingNip({ ...editingNip, localNip: value });
    }
  };

  const handleCopyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setCopyNotification(true);
      setTimeout(() => setCopyNotification(false), 2000); // O aviso desaparece após 2 segundos
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8 flex-grow flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-700">NIPs Locais</h2>
        <button 
          onClick={() => setShowAddNip(!showAddNip)}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 flex items-center"
        >
          <FaPlus className="mr-2" />
          {showAddNip ? 'Cancelar' : 'Adicionar'}
        </button>
      </div>

      {showAddNip && (
        <div className="p-6 bg-white border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-700 mb-4">Novo NIP Local</h3>
          <div className="space-y-4 md:space-y-0 md:flex md:space-x-4">
            <div className="flex-1">
              <label htmlFor="local-name" className="block text-sm font-medium text-gray-700 mb-1">
                Local <span className="text-red-500">*</span>
              </label>
              <input
                id="local-name"
                type="text"
                placeholder="Nome do local"
                value={newNip.localName}
                onChange={handleLocalNameChange}
                className={`w-full p-2 border ${errors.localName ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                required
              />
              {errors.localName && <p className="text-red-500 text-xs mt-1">{errors.localName}</p>}
            </div>
            <div className="flex-1">
              <label htmlFor="local-nip" className="block text-sm font-medium text-gray-700 mb-1">
                Nº NIP <span className="text-red-500">*</span>
              </label>
              <input
                id="local-nip"
                type="text"
                placeholder="Número do NIP"
                value={newNip.localNip}
                onChange={handleLocalNipChange}
                className={`w-full p-2 border ${errors.localNip ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                required
              />
              {errors.localNip && <p className="text-red-500 text-xs mt-1">{errors.localNip}</p>}
            </div>
            <div className="flex items-end">
              <button 
                onClick={handleAddNip}
                className="w-full md:w-auto bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300"
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      )}

      <div className="p-6">
        <div className="mb-4">
          <div className="relative">
            <input
              type="text"
              placeholder="Pesquisar NIPs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full p-2 pl-8 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            {searchQuery && (
              <button
                onClick={clearSearch}
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FaTimes />
              </button>
            )}
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Local</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nº NIP</th>
                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredNips.map((nip) => (
                <tr key={nip.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm font-medium text-gray-900">{nip.localName}</div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="text-sm font-medium text-gray-900">{nip.localNip}</div>
                      <button
                        onClick={() => handleCopyToClipboard(nip.localNip)}
                        className="ml-2 text-green-500 hover:text-green-700 p-2"
                        title="Copiar para Clipboard"
                      >
                        <FaCopy />
                      </button>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <div className="flex space-x-2">
                      <button 
                        onClick={() => handleEditClick(nip)}
                        className="text-yellow-500 hover:text-yellow-700 p-2"
                        title="Editar"
                      >
                        <FaEdit />
                      </button>
                      <button 
                        onClick={() => handleDeleteNip(nip.id, nip.localName)}
                        className="text-red-500 hover:text-red-700 p-2"
                        title="Excluir"
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {editingNip && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Editar NIP Local</h3>
            <div className="space-y-4">
              <div>
                <label htmlFor="edit-local-name" className="block text-sm font-medium text-gray-700 mb-1">
                  Local <span className="text-red-500">*</span>
                </label>
                <input
                  id="edit-local-name"
                  type="text"
                  value={editingNip.localName}
                  onChange={handleEditLocalNameChange}
                  className={`w-full p-2 border ${errors.localName ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  placeholder="Nome do local"
                  required
                />
                {errors.localName && <p className="text-red-500 text-xs mt-1">{errors.localName}</p>}
              </div>
              <div>
                <label htmlFor="edit-local-nip" className="block text-sm font-medium text-gray-700 mb-1">
                  Nº NIP <span className="text-red-500">*</span>
                </label>
                <input
                  id="edit-local-nip"
                  type="text"
                  value={editingNip.localNip}
                  onChange={handleEditLocalNipChange}
                  className={`w-full p-2 border ${errors.localNip ? 'border-red-500' : 'border-gray-300'} rounded-md`}
                  placeholder="Número do NIP"
                  required
                />
                {errors.localNip && <p className="text-red-500 text-xs mt-1">{errors.localNip}</p>}
              </div>
            </div>
            <div className="flex justify-end mt-6 space-x-2">
              <button
                onClick={() => setEditingNip(null)}
                className="px-4 py-2 bg-gray-300 text-black rounded-md hover:bg-gray-400"
              >
                Cancelar
              </button>
              <button
                onClick={handleSaveEdit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar exclusão</h3>
            <p className="mb-4">Tem certeza de que deseja excluir o NIP Local "{confirmDelete.localName}"?</p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={() => setConfirmDelete(null)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={confirmDeleteNip}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}

      {copyNotification && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg max-w-xs w-full mx-4">
          Nº NIP Local copiado para a área de transferência!
        </div>
      )}
    </div>
  );
};

export default Nips;