import React, { useState, useEffect } from 'react';
import { FaEdit, FaTrash, FaEye, FaTimes, FaUserPlus } from 'react-icons/fa';
import { collection, addDoc, query, onSnapshot, doc, updateDoc, deleteDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase/config';

const Identificacoes = () => {
  const [identificacoes, setIdentificacoes] = useState([]);
  const [formData, setFormData] = useState({
    nome: '', cc: '', dataNascimento: '', validadeCC: '', nif: '', nacionalidade: '',
    morada: '', codigoPostal: '', freguesia: '', estadoCivil: '', profissao: '',
    escolaridade: '', telefone: '', nomeMae: '', nomePai: '', diaOcorrencia: '',
    horaOcorrencia: '', ruaOcorrencia: '', informacoesAdicionais: ''
  });
  const [editingId, setEditingId] = useState(null);
  const [viewingIdentificacao, setViewingIdentificacao] = useState(null);

  useEffect(() => {
    const q = query(collection(db, 'identificacoes'));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const identificacoesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setIdentificacoes(identificacoesData);
    });

    // Definir a data e hora atuais quando o componente é montado
    const now = new Date();
    setFormData(prevState => ({
      ...prevState,
      diaOcorrencia: now.toLocaleDateString('pt-BR'),
      horaOcorrencia: now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
    }));

    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = {
        ...formData,
        diaOcorrencia: formData.diaOcorrencia || new Date().toLocaleDateString('pt-BR'),
        horaOcorrencia: formData.horaOcorrencia || new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })
      };

      if (editingId) {
        await updateDoc(doc(db, 'identificacoes', editingId), {
          ...dataToSubmit,
          lastEditedBy: auth.currentUser.displayName || auth.currentUser.email,
          lastEditedAt: serverTimestamp()
        });
      } else {
        await addDoc(collection(db, 'identificacoes'), {
          ...dataToSubmit,
          createdBy: auth.currentUser.displayName || auth.currentUser.email,
          createdAt: serverTimestamp(),
          lastEditedBy: auth.currentUser.displayName || auth.currentUser.email,
          lastEditedAt: serverTimestamp()
        });
      }
      resetForm();
    } catch (error) {
      console.error("Error submitting form: ", error);
    }
  };

  const resetForm = () => {
    const now = new Date();
    setFormData({
      nome: '', cc: '', dataNascimento: '', validadeCC: '', nif: '', nacionalidade: '',
      morada: '', codigoPostal: '', freguesia: '', estadoCivil: '', profissao: '',
      escolaridade: '', telefone: '', nomeMae: '', nomePai: '',
      diaOcorrencia: now.toLocaleDateString('pt-BR'),
      horaOcorrencia: now.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' }),
      ruaOcorrencia: '', informacoesAdicionais: ''
    });
    setEditingId(null);
  };

  const handleEdit = (identificacao) => {
    setFormData(identificacao);
    setEditingId(identificacao.id);
  };

  const handleDelete = async (id) => {
    if (window.confirm('Tem certeza que deseja excluir esta identificação?')) {
      try {
        await deleteDoc(doc(db, 'identificacoes', id));
      } catch (error) {
        console.error("Error deleting identification: ", error);
      }
    }
  };

  const handleView = (identificacao) => {
    setViewingIdentificacao(identificacao);
  };

  const formatValue = (value) => {
    if (value && typeof value === 'object') {
      if (value.seconds) {
        // É um timestamp do Firestore
        return new Date(value.seconds * 1000).toLocaleString('pt-BR');
      } else if (value.toDate) {
        // É um objeto Timestamp do Firestore
        return value.toDate().toLocaleString('pt-BR');
      } else {
        // É um objeto, mas não é um timestamp
        return JSON.stringify(value);
      }
    }
    if (value === null || value === undefined) {
      return 'N/A';
    }
    // Decodifica caracteres especiais
    return decodeURIComponent(escape(value.toString()));
  };

  const formatFieldName = (key) => {
    const fieldNames = {
      nome: 'Nome',
      cc: 'CC Nº',
      dataNascimento: 'Data Nascimento',
      validadeCC: 'Validade CC',
      nif: 'NIF',
      nacionalidade: 'Nacionalidade',
      morada: 'Morada e Nº Porta',
      codigoPostal: 'C. Postal',
      freguesia: 'Freguesia',
      estadoCivil: 'Estado civil',
      profissao: 'Profissão',
      escolaridade: 'Escolaridade',
      telefone: 'Telefone',
      nomeMae: 'Nome Mãe',
      nomePai: 'Nome Pai',
      diaOcorrencia: 'Dia da Ocorrência',
      horaOcorrencia: 'Hora da ocorrência',
      ruaOcorrencia: 'Local da Ocorrência',
      informacoesAdicionais: 'Informações Adic...',
      lastEditedBy: 'Última edição por'
    };
    return fieldNames[key] || decodeURIComponent(escape(key.charAt(0).toUpperCase() + key.slice(1)));
  };

  const orderedFields = [
    'nome', 'cc', 'dataNascimento', 'validadeCC', 'nif', 'nacionalidade',
    'morada', 'codigoPostal', 'freguesia', 'estadoCivil', 'profissao',
    'escolaridade', 'telefone', 'nomeMae', 'nomePai', 'diaOcorrencia',
    'horaOcorrencia', 'ruaOcorrencia', 'informacoesAdicionais', 'lastEditedBy'
  ];

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8 flex-grow flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-700">Identificações</h2>
      </div>

      {/* Formulário */}
      <form onSubmit={handleSubmit} className="mb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label htmlFor="nome" className="block text-gray-700 text-sm font-bold mb-2">Nome Completo</label>
            <input id="nome" name="nome" value={formData.nome} onChange={handleInputChange} placeholder="Nome completo" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="cc" className="block text-gray-700 text-sm font-bold mb-2">Cartão de Cidadão</label>
            <input id="cc" name="cc" value={formData.cc} onChange={handleInputChange} placeholder="Número do CC" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="dataNascimento" className="block text-gray-700 text-sm font-bold mb-2">Data de Nascimento</label>
            <input id="dataNascimento" name="dataNascimento" type="text" value={formData.dataNascimento} onChange={handleInputChange} placeholder="DD/MM/AAAA" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="validadeCC" className="block text-gray-700 text-sm font-bold mb-2">Validade do CC</label>
            <input id="validadeCC" name="validadeCC" type="text" value={formData.validadeCC} onChange={handleInputChange} placeholder="DD/MM/AAAA" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="nif" className="block text-gray-700 text-sm font-bold mb-2">NIF</label>
            <input id="nif" name="nif" value={formData.nif} onChange={handleInputChange} placeholder="Número de Identificação Fiscal" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="nacionalidade" className="block text-gray-700 text-sm font-bold mb-2">Nacionalidade</label>
            <input id="nacionalidade" name="nacionalidade" value={formData.nacionalidade} onChange={handleInputChange} placeholder="País de origem" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="morada" className="block text-gray-700 text-sm font-bold mb-2">Morada</label>
            <input id="morada" name="morada" value={formData.morada} onChange={handleInputChange} placeholder="Endereço completo" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="codigoPostal" className="block text-gray-700 text-sm font-bold mb-2">Código Postal</label>
            <input id="codigoPostal" name="codigoPostal" value={formData.codigoPostal} onChange={handleInputChange} placeholder="0000-000" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="freguesia" className="block text-gray-700 text-sm font-bold mb-2">Freguesia</label>
            <input id="freguesia" name="freguesia" value={formData.freguesia} onChange={handleInputChange} placeholder="Nome da freguesia" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="estadoCivil" className="block text-gray-700 text-sm font-bold mb-2">Estado Civil</label>
            <input id="estadoCivil" name="estadoCivil" value={formData.estadoCivil} onChange={handleInputChange} placeholder="Ex: Solteiro, Casado, etc." className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="profissao" className="block text-gray-700 text-sm font-bold mb-2">Profissão</label>
            <input id="profissao" name="profissao" value={formData.profissao} onChange={handleInputChange} placeholder="Ocupação atual" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="escolaridade" className="block text-gray-700 text-sm font-bold mb-2">Escolaridade</label>
            <input id="escolaridade" name="escolaridade" value={formData.escolaridade} onChange={handleInputChange} placeholder="Nível de educação" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="telefone" className="block text-gray-700 text-sm font-bold mb-2">Telefone</label>
            <input id="telefone" name="telefone" value={formData.telefone} onChange={handleInputChange} placeholder="Número de contato" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="nomeMae" className="block text-gray-700 text-sm font-bold mb-2">Nome da Mãe</label>
            <input id="nomeMae" name="nomeMae" value={formData.nomeMae} onChange={handleInputChange} placeholder="Nome completo da mãe" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="nomePai" className="block text-gray-700 text-sm font-bold mb-2">Nome do Pai</label>
            <input id="nomePai" name="nomePai" value={formData.nomePai} onChange={handleInputChange} placeholder="Nome completo do pai" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="diaOcorrencia" className="block text-gray-700 text-sm font-bold mb-2">Dia da Ocorrência</label>
            <input id="diaOcorrencia" name="diaOcorrencia" type="text" value={formData.diaOcorrencia} onChange={handleInputChange} placeholder="DD/MM/AAAA" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="horaOcorrencia" className="block text-gray-700 text-sm font-bold mb-2">Hora da Ocorrência</label>
            <input id="horaOcorrencia" name="horaOcorrencia" type="text" value={formData.horaOcorrencia} onChange={handleInputChange} placeholder="HH:MM" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
          <div>
            <label htmlFor="ruaOcorrencia" className="block text-gray-700 text-sm font-bold mb-2">Local da Ocorrência</label>
            <input id="ruaOcorrencia" name="ruaOcorrencia" value={formData.ruaOcorrencia} onChange={handleInputChange} placeholder="Endereço da ocorrência" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" />
          </div>
        </div>
        <div className="mt-4">
          <label htmlFor="informacoesAdicionais" className="block text-gray-700 text-sm font-bold mb-2">Informações Adicionais</label>
          <textarea id="informacoesAdicionais" name="informacoesAdicionais" value={formData.informacoesAdicionais} onChange={handleInputChange} placeholder="Detalhes adicionais relevantes" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" rows="4"></textarea>
        </div>
        <button type="submit" className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4">
          {editingId ? 'Atualizar' : 'Adicionar'} Identificação
        </button>
      </form>

      {/* Lista de Identificações */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Registado por</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {identificacoes.map((identificacao) => (
              <tr key={identificacao.id}>
                <td className="px-6 py-4 whitespace-nowrap">{identificacao.nome}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <FaUserPlus className="mr-1 text-blue-500" title="Registado por" />
                    {identificacao.createdBy || 'Usuário desconhecido'}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex space-x-2">
                    <button 
                      onClick={() => handleView(identificacao)} 
                      className="text-blue-500 hover:text-blue-700 p-2"
                      title="Visualizar"
                    >
                      <FaEye />
                    </button>
                    <button 
                      onClick={() => handleEdit(identificacao)} 
                      className="text-yellow-500 hover:text-yellow-700 p-2"
                      title="Editar"
                    >
                      <FaEdit />
                    </button>
                    <button 
                      onClick={() => handleDelete(identificacao.id)} 
                      className="text-red-500 hover:text-red-700 p-2"
                      title="Excluir"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pop-up para visualizar detalhes */}
      {viewingIdentificacao && (
        <div className="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center z-50">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl mx-4 p-6 max-h-[90vh] overflow-y-auto">
            <div className="sticky top-0 bg-white pb-4 mb-4 border-b">
              <div className="flex justify-between items-center">
                <h3 className="text-2xl font-bold text-gray-800">Detalhes da Identificação</h3>
                <button
                  onClick={() => setViewingIdentificacao(null)}
                  className="text-gray-600 hover:text-gray-800 transition duration-150"
                >
                  <FaTimes className="w-6 h-6" />
                </button>
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {orderedFields.map((key) => (
                viewingIdentificacao[key] !== undefined && (
                  <div key={key} className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                      {formatFieldName(key)}
                    </label>
                    <div className="bg-gray-100 p-3 rounded-md overflow-x-auto">
                      {formatValue(viewingIdentificacao[key])}
                    </div>
                  </div>
                )
              ))}
            </div>
            <div className="sticky bottom-0 bg-white pt-4 mt-4 border-t">
              <div className="flex justify-end">
                <button
                  onClick={() => setViewingIdentificacao(null)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition duration-150"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Identificacoes;