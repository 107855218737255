import React, { useState, useEffect } from 'react';
import { FaBars, FaTimes, FaPen, FaWpforms, FaSignOutAlt, FaAddressBook, FaIdCard, FaHome, FaUser, FaCar } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { signOut } from 'firebase/auth';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc, serverTimestamp, query, orderBy, limit } from 'firebase/firestore';
import { ref, set, onValue, remove } from 'firebase/database';
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { auth, db, rtdb, storage } from '../firebase/config';
import Texts from './dashboard/Texts';
import Forms from './dashboard/Forms';
import Contacts from './dashboard/Contacts';
import Nips from './dashboard/Nips';
import Home from './dashboard/Home';
import MyAccount from './dashboard/MyAccount';
import Viagens from './dashboard/Viagens';
import Identificacoes from './dashboard/Identificacoes';
import { SearchProvider } from '../context/SearchContext';

const Dashboard = ({ user }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('home');
  const [texts, setTexts] = useState([]);
  const [forms, setForms] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [nips, setNips] = useState([]);

  useEffect(() => {
    if (user) {
      const fetchData = async () => {
        await fetchTexts();
        await fetchForms();
        await fetchContacts();
        await fetchNips();
      };

      fetchData();
      updateOnlineStatus(user.uid, true);

      return () => {
        updateOnlineStatus(user.uid, false);
      };
    }
  }, [user]);

  const fetchTexts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "texts"));
      const fetchedTexts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTexts(fetchedTexts);

      fetchedTexts.forEach(text => {
        const viewCountRef = ref(rtdb, `view_counts/${text.id}`);
        onValue(viewCountRef, (snapshot) => {
          const viewCount = snapshot.val() || 0;
          setTexts(prevTexts => 
            prevTexts.map(t => 
              t.id === text.id ? { ...t, viewCount } : t
            )
          );
        });
      });
    } catch (error) {
      console.error("Error fetching texts: ", error);
    }
  };

  const fetchForms = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "files"));
      const fetchedForms = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          title: data.name,
          type: data.type,
          size: data.size,
          uploadedBy: data.uploadedBy,
          uploadedAt: data.uploadedAt.toDate(),
          storageUrl: data.storageUrl
        };
      });
      setForms(fetchedForms);
    } catch (error) {
      console.error("Error fetching forms: ", error);
    }
  };

  const fetchContacts = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "contacts"));
      const fetchedContacts = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setContacts(fetchedContacts);
    } catch (error) {
      console.error("Error fetching contacts: ", error);
    }
  };

  const fetchNips = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "nips"));
      const fetchedNips = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNips(fetchedNips);
    } catch (error) {
      console.error("Error fetching NIPs: ", error);
    }
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const addActivity = async (type, action, description) => {
    try {
      // Add the new activity
      await addDoc(collection(db, "activities"), {
        type,
        action,
        description,
        timestamp: serverTimestamp(),
        userId: user.uid,
        userName: user.displayName || user.email
      });

      // Query to get the oldest activities if there are more than 10
      const activitiesRef = collection(db, "activities");
      const q = query(activitiesRef, orderBy("timestamp", "asc"));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.size > 10) {
        // Calculate how many documents to delete
        const deleteCount = querySnapshot.size - 10;
        
        // Delete the oldest activities
        for (let i = 0; i < deleteCount; i++) {
          const docToDelete = querySnapshot.docs[i];
          await deleteDoc(doc(db, "activities", docToDelete.id));
        }
      }
    } catch (error) {
      console.error("Error managing activities: ", error);
    }
  };

  const addText = async (newText) => {
    try {
      const docRef = await addDoc(collection(db, "texts"), {
        ...newText,
        createdAt: serverTimestamp(),
        lastEditedAt: serverTimestamp(),
      });
      await set(ref(rtdb, `view_counts/${docRef.id}`), 0);
      setTexts([...texts, { ...newText, id: docRef.id, viewCount: 0 }]);
      await addActivity('text', 'add', `Novo texto adicionado: ${newText.title}`);
    } catch (error) {
      console.error("Error adding text: ", error);
    }
  };

  const editText = async (id, updatedText) => {
    try {
      await updateDoc(doc(db, "texts", id), {
        ...updatedText,
        lastEditedAt: serverTimestamp(),
      });
      setTexts(texts.map(text => text.id === id ? { ...text, ...updatedText } : text));
      await addActivity('text', 'edit', `Texto editado: ${updatedText.title}`);
    } catch (error) {
      console.error("Error updating text: ", error);
    }
  };

  const deleteText = async (id) => {
    try {
      const textToDelete = texts.find(text => text.id === id);
      await deleteDoc(doc(db, "texts", id));
      await remove(ref(rtdb, `view_counts/${id}`));
      setTexts(texts.filter(text => text.id !== id));
      await addActivity('text', 'delete', `Texto exclu\u00EDdo: ${textToDelete.title}`);
    } catch (error) {
      console.error("Error deleting text: ", error);
    }
  };

  const addForm = async (newForm) => {
    try {
      const fileRef = storageRef(storage, `files/${newForm.file.name}`);
      await uploadBytes(fileRef, newForm.file);
      const downloadURL = await getDownloadURL(fileRef);

      const docRef = await addDoc(collection(db, "files"), {
        name: newForm.title,
        type: newForm.file.type,
        size: newForm.file.size,
        uploadedBy: user.uid,
        uploadedAt: serverTimestamp(),
        lastEditedAt: serverTimestamp(),
        storageUrl: downloadURL
      });

      const newFormData = {
        id: docRef.id,
        title: newForm.title,
        type: newForm.file.type,
        size: newForm.file.size,
        uploadedBy: user.uid,
        uploadedAt: new Date(),
        lastEditedAt: serverTimestamp(),
        storageUrl: downloadURL
      };

      setForms([...forms, newFormData]);
      await addActivity('form', 'add', `Novo formulário adicionado: ${newForm.title}`);
    } catch (error) {
      console.error("Error adding form: ", error);
    }
  };

  const editForm = async (id, updatedForm) => {
    try {
      await updateDoc(doc(db, "files", id), {
        name: updatedForm.title,
        lastEditedAt: serverTimestamp(),
      });
      setForms(forms.map(form => form.id === id ? { ...form, title: updatedForm.title, lastEditedAt: serverTimestamp() } : form));
      await addActivity('form', 'edit', `Formulário editado: ${updatedForm.title}`);
    } catch (error) {
      console.error("Error updating form: ", error);
    }
  };

  const deleteForm = async (id) => {
    console.log("deleteForm function called with id:", id);
    try {
      const formToDelete = forms.find(form => form.id === id);
      console.log("Form to delete:", formToDelete);
      if (formToDelete) {
        console.log("Deleting document from Firestore");
        await deleteDoc(doc(db, "files", id));
        console.log("Document deleted from Firestore");

        try {
          console.log("Attempting to delete file from storage");
          const fileRef = storageRef(storage, formToDelete.storageUrl);
          await deleteObject(fileRef);
          console.log("File deleted from storage");
        } catch (storageError) {
          console.error("Error deleting file from storage:", storageError);
          // Continue with the process even if storage deletion fails
        }

        console.log("Updating local state");
        setForms(forms.filter(form => form.id !== id));
        console.log("Local state updated");
        await addActivity('form', 'delete', `Formul\u00E1rio exclu\u00EDdo: ${formToDelete.title}`);
        return true; // Indicate successful deletion
      } else {
        console.error("Form not found with id:", id);
        return false; // Indicate failure
      }
    } catch (error) {
      console.error("Error deleting form: ", error);
      return false; // Indicate failure
    }
  };

  const addContact = async (newContact) => {
    try {
      const docRef = await addDoc(collection(db, "contacts"), {
        ...newContact,
        createdAt: new Date()
      });
      setContacts([...contacts, { ...newContact, id: docRef.id }]);
      await addActivity('contact', 'add', `Novo contato adicionado: ${newContact.name}`);
    } catch (error) {
      console.error("Error adding contact: ", error);
    }
  };

  const editContact = async (id, updatedContact) => {
    try {
      await updateDoc(doc(db, "contacts", id), updatedContact);
      setContacts(contacts.map(contact => contact.id === id ? { ...contact, ...updatedContact } : contact));
      await addActivity('contact', 'edit', `Contato editado: ${updatedContact.name}`);
    } catch (error) {
      console.error("Error updating contact: ", error);
    }
  };

  const deleteContact = async (id) => {
    try {
      const contactToDelete = contacts.find(contact => contact.id === id);
      await deleteDoc(doc(db, "contacts", id));
      setContacts(contacts.filter(contact => contact.id !== id));
      await addActivity('contact', 'delete', `Contato exclu\u00EDdo: ${contactToDelete.name}`);
    } catch (error) {
      console.error("Error deleting contact: ", error);
    }
  };

  const addNip = async (newNip) => {
    try {
      const docRef = await addDoc(collection(db, "nips"), {
        ...newNip,
        createdAt: new Date()
      });
      setNips([...nips, { ...newNip, id: docRef.id }]);
      await addActivity('nip', 'add', `Novo NIP adicionado: ${newNip.name}`);
    } catch (error) {
      console.error("Error adding NIP: ", error);
    }
  };

  const editNip = async (id, updatedNip) => {
    try {
      await updateDoc(doc(db, "nips", id), updatedNip);
      setNips(nips.map(nip => nip.id === id ? { ...nip, ...updatedNip } : nip));
      await addActivity('nip', 'edit', `NIP editado: ${updatedNip.name}`);
    } catch (error) {
      console.error("Error updating NIP: ", error);
    }
  };

  const deleteNip = async (id) => {
    try {
      const nipToDelete = nips.find(nip => nip.id === id);
      await deleteDoc(doc(db, "nips", id));
      setNips(nips.filter(nip => nip.id !== id));
      await addActivity('nip', 'delete', `NIP exclu\u00EDdo: ${nipToDelete.name}`);
    } catch (error) {
      console.error("Error deleting NIP: ", error);
    }
  };

  const updateOnlineStatus = (userId, status) => {
    if (userId) {
      set(ref(rtdb, `online_status/${userId}`), status);
    }
  };

  const handleLogout = async () => {
    try {
      if (user) {
        updateOnlineStatus(user.uid, false);
      }
      await signOut(auth);
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log('Texto copiado para a área de transferência');
      // Opcionalmente, você pode adicionar uma notificação para o usuário aqui
    } catch (err) {
      console.error('Falha ao copiar texto: ', err);
    }
  };

  const handleUpdateProfile = (updatedProfile) => {
    // Atualizar o estado do usuário localmente, se necessário
    // Isso depende de como você está gerenciando o estado do usuário em sua aplicação
    // Por exemplo, se você estiver usando um contexto de usuário:
    // updateUserContext({ ...user, ...updatedProfile });
    
    // Atualizar as atividades localmente
    setTexts(prevTexts => prevTexts.map(text => ({
      ...text,
      authorName: text.authorId === user.uid ? updatedProfile.displayName : text.authorName
    })));
    
    // Você pode precisar atualizar outros estados que contêm o nome do usuário
  };

  if (!user) {
    return null;
  }

  return (
    <SearchProvider>
      <div className="flex h-screen bg-gray-100 font-sans">
        {/* Sidebar */}
        <motion.div
          className={`bg-gray-800 text-white w-64 space-y-6 py-7 px-2 absolute inset-y-0 left-0 transform ${
            sidebarOpen ? 'translate-x-0' : '-translate-x-full'
          } md:relative md:translate-x-0 transition duration-200 ease-in-out z-20 flex flex-col justify-between h-full`}
          initial={false}
          animate={sidebarOpen ? 'open' : 'closed'}
        >
          <div>
            <div className="flex items-center justify-between px-4 mb-6">
              <span className="text-2xl font-semibold">Dashboard</span>
              <button onClick={toggleSidebar} className="p-2 rounded-md md:hidden hover:bg-gray-700">
                {sidebarOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
              </button>
            </div>
            <nav className="text-gray-300">
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'home' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('home')}
              >
                <FaHome className="inline-block mr-2" /> Home
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'texts' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('texts')}
              >
                <FaPen className="inline-block mr-2" /> Textos
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'forms' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('forms')}
              >
                <FaWpforms className="inline-block mr-2" /> Formulários
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'contacts' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('contacts')}
              >
                <FaAddressBook className="inline-block mr-2" /> Contatos
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'nips' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('nips')}
              >
                <FaIdCard className="inline-block mr-2" /> NIPs
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'viagens' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('viagens')}
              >
                <FaCar className="inline-block mr-2" /> Viagens
              </a>
              <a
                href="#"
                className={`block py-2.5 px-4 rounded transition duration-200 ${
                  activeTab === 'identificacoes' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
                }`}
                onClick={() => setActiveTab('identificacoes')}
              >
                <FaIdCard className="inline-block mr-2" /> Identificações
              </a>
            </nav>
          </div>
          <div>
            <a
              href="#"
              className={`flex items-center py-2.5 px-4 rounded transition duration-200 ${
                activeTab === 'myaccount' ? 'bg-blue-500 text-white' : 'hover:bg-gray-700 hover:text-white'
              }`}
              onClick={() => setActiveTab('myaccount')}
            >
              <FaUser className="mr-2" />
              <span>Minha Conta</span>
            </a>
            <button 
              onClick={handleLogout} 
              className="flex items-center w-full py-2.5 px-4 mt-2 rounded transition duration-200 hover:bg-gray-700 hover:text-white"
            >
              <FaSignOutAlt className="mr-2" />
              <span>Logout</span>
            </button>
          </div>
        </motion.div>

        {/* Main Content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <header className="flex justify-between items-center p-4 bg-white shadow-md">
            <button onClick={toggleSidebar} className="p-2 rounded-md md:hidden hover:bg-gray-200">
              {sidebarOpen ? <FaTimes className="w-6 h-6" /> : <FaBars className="w-6 h-6" />}
            </button>
            <h1 className="text-2xl font-semibold">
              {activeTab === 'myaccount' ? 'Minha Conta' : activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
            </h1>
            <div></div> {/* Placeholder for symmetry */}
          </header>
          <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 responsive-padding">
            {activeTab === 'home' && (
              <Home 
                setActiveTab={setActiveTab}
                addText={addText}
                editText={editText}
                deleteText={deleteText}
                addForm={addForm}
                editForm={editForm}
                deleteForm={deleteForm}
                addContact={addContact}
                editContact={editContact}
                deleteContact={deleteContact}
                addNip={addNip}
                editNip={editNip}
                deleteNip={deleteNip}
              />
            )}
            {activeTab === 'texts' && (
              <Texts 
                texts={texts} 
                addText={addText} 
                editText={editText} 
                deleteText={deleteText} 
                copyToClipboard={copyToClipboard}
              />
            )}
            {activeTab === 'forms' && (
              <Forms 
                forms={forms} 
                addForm={addForm} 
                editForm={editForm} 
                deleteForm={deleteForm} 
              />
            )}
            {activeTab === 'contacts' && (
              <Contacts 
                contacts={contacts}
                addContact={addContact}
                editContact={editContact}
                deleteContact={deleteContact}
              />
            )}
            {activeTab === 'nips' && (
              <Nips 
                nips={nips}
                addNip={addNip}
                editNip={editNip}
                deleteNip={deleteNip}
              />
            )}
            {activeTab === 'myaccount' && (
              <MyAccount user={user} onUpdateProfile={handleUpdateProfile} />
            )}
            {activeTab === 'viagens' && (
              <Viagens />
            )}
            {activeTab === 'identificacoes' && (
              <Identificacoes />
            )}
          </main>
        </div>
      </div>
    </SearchProvider>
  );
};

export default Dashboard;