import React, { useState, useEffect, useCallback } from 'react';
import { FaFileAlt, FaWpforms, FaAddressBook, FaIdCard, FaSearch, FaPlus, FaEdit, FaTrash, FaUser, FaFile, FaCar, FaTimes } from 'react-icons/fa';
import { collection, query, orderBy, limit, onSnapshot, getDocs } from 'firebase/firestore';
import { db } from '../../firebase/config';
import debounce from 'lodash/debounce';
import { motion, AnimatePresence } from 'framer-motion';
import { useSearch } from '../../context/SearchContext';

const formatDate = (timestamp) => {
  if (timestamp && timestamp.toDate) {
    const date = timestamp.toDate();
    return new Intl.DateTimeFormat('pt-BR', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit'
    }).format(date);
  }
  return 'Data desconhecida';
};

const decodeUnicode = (str) => {
  return str.replace(/\\u[\dA-F]{4}/gi, 
    function (match) {
      return String.fromCharCode(parseInt(match.replace(/\\u/g, ''), 16));
    });
};

const Home = ({ setActiveTab }) => {
  const { searchQuery, setSearchQuery } = useSearch();
  const [activities, setActivities] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState(null);

  useEffect(() => {
    const activitiesRef = collection(db, "activities");
    const q = query(activitiesRef, orderBy("timestamp", "desc"), limit(10));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const newActivities = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setActivities(newActivities);
    });

    return () => unsubscribe();
  }, []);

  const getActivityIcon = (type) => {
    switch (type) {
      case 'text': return <FaFileAlt className="text-blue-500" />;
      case 'form': return <FaWpforms className="text-green-500" />;
      case 'contact': return <FaAddressBook className="text-purple-500" />;
      case 'nip': return <FaIdCard className="text-yellow-500" />;
      case 'viagem': return <FaCar className="text-red-500" />;
      default: return <FaFile className="text-gray-500" />;
    }
  };

  const getActivityTypeText = (type) => {
    switch (type) {
      case 'text': return 'Texto';
      case 'form': return 'Formulário';
      case 'contact': return 'Contato';
      case 'nip': return 'NIP';
      case 'viagem': return 'Viagem';
      default: return 'Item';
    }
  };

  const getActionIcon = (action) => {
    switch (action) {
      case 'add': return <FaPlus className="text-green-600" />;
      case 'edit': return <FaEdit className="text-blue-600" />;
      case 'delete': return <FaTrash className="text-red-600" />;
      default: return null;
    }
  };

  const getActionColor = (action) => {
    switch (action) {
      case 'add': return 'bg-green-100 border-green-200';
      case 'edit': return 'bg-blue-100 border-blue-200';
      case 'delete': return 'bg-red-100 border-red-200';
      default: return 'bg-gray-100 border-gray-200';
    }
  };

  const getActionText = (action) => {
    switch (action) {
      case 'add': return 'Adicionado';
      case 'edit': return 'Editado';
      case 'delete': return 'Exclu\u00EDdo';
      default: return 'A\u00E7\u00E3o desconhecida';
    }
  };

  const performSearch = useCallback(async (searchQuery) => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }

    const results = [];
    const collections = ['texts', 'files', 'contacts', 'nips'];
    const searchTerm = searchQuery.toLowerCase().trim();

    for (const collectionName of collections) {
      const collectionRef = collection(db, collectionName);
      const q = query(collectionRef);
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const title = (data.title || data.name || '').toLowerCase();
        
        if (title.includes(searchTerm)) {
          results.push({
            id: doc.id,
            type: collectionName,
            title: data.title || data.name || 'Sem título',
          });
        }
      });
    }

    setSearchResults(results);
  }, []);

  const debouncedSearch = useCallback(
    debounce((query) => performSearch(query), 300),
    [performSearch]
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery, debouncedSearch]);

  const handleResultClick = (result) => {
    switch (result.type) {
      case 'text':
      case 'texts':
        setActiveTab('texts');
        break;
      case 'form':
      case 'file':
        setActiveTab('forms');
        break;
      case 'contact':
      case 'contacts':
        setActiveTab('contacts');
        break;
      case 'nip':
      case 'nips':
        setActiveTab('nips');
        break;
      default:
        console.log('Tipo de resultado:', result.type);
        setActiveTab('forms'); // Fallback para a aba de formulários
    }
  };

  const getFileIcon = (type) => {
    switch (type) {
      case 'text':
      case 'texts':
        return <FaFileAlt className="text-blue-500" />;
      case 'form':
        return <FaWpforms className="text-green-500" />;
      case 'file':
        return <FaFile className="text-orange-500" />;
      case 'contact':
      case 'contacts':
        return <FaAddressBook className="text-purple-500" />;
      case 'nip':
      case 'nips':
        return <FaIdCard className="text-yellow-500" />;
      case 'viagem':
        return <FaCar className="text-red-500" />;
      default:
        return <FaFile className="text-gray-500" />;
    }
  };

  const getFileTypeText = (type) => {
    switch (type) {
      case 'texts':
        return 'Texto';
      case 'files':
      case 'form':
        return 'Formulário';
      case 'contacts':
        return 'Contato';
      case 'nips':
        return 'NIP';
      default:
        return 'Arquivo';
    }
  };

  // Função para abrir o pop-up
  const openPopup = (activity) => {
    setSelectedActivity(activity);
  };

  // Função para fechar o pop-up
  const closePopup = () => {
    setSelectedActivity(null);
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    // ... lógica de pesquisa existente ...
  };

  return (
    <div className="bg-gray-100 min-h-screen p-6">
      <div className="max-w-4xl mx-auto">
        {/* Barra de pesquisa */}
        <div className="mb-8">
          <div className="bg-white rounded-lg shadow-md p-4">
            <div className="flex items-center border-b-2 border-blue-500 py-2">
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Pesquisar em todos os arquivos..."
                value={searchQuery}
                onChange={handleSearch}
              />
              <FaSearch className="text-blue-500" />
            </div>
          </div>
          {/* Resultados da pesquisa */}
          {searchResults.length > 0 && (
            <div className="mt-2 bg-white rounded-lg shadow-md max-h-60 overflow-auto">
              {searchResults.map((result) => (
                <div 
                  key={result.id} 
                  className="p-2 hover:bg-gray-100 cursor-pointer flex items-center"
                  onClick={() => handleResultClick(result)}
                >
                  {getFileIcon(result.type)}
                  <div className="ml-2 flex-grow">
                    <span className="text-sm font-semibold">{result.title}</span>
                    <span className="text-xs text-gray-500 ml-2">
                      {getFileTypeText(result.type)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Atividades Recentes */}
        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-2xl font-bold text-gray-800 mb-4">Atividades Recentes</h3>
          <div className="space-y-4">
            {activities.map((activity) => {
              const [action, ...titleParts] = decodeUnicode(activity.description).split(':');
              const title = titleParts.join(':').trim();
              return (
                <div 
                  key={activity.id} 
                  className={`p-4 rounded-lg ${getActionColor(activity.action)} border cursor-pointer transition-all duration-300 hover:shadow-lg`}
                  onClick={() => openPopup(activity)}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {getActivityIcon(activity.type)}
                      <span className="ml-2 text-sm font-medium text-gray-600">
                        {getActivityTypeText(activity.type)}
                      </span>
                    </div>
                    <span className={`text-sm font-bold px-2 py-1 rounded ${
                      activity.action === 'add' ? 'bg-green-200 text-green-800' :
                      activity.action === 'edit' ? 'bg-blue-200 text-blue-800' :
                      'bg-red-200 text-red-800'
                    }`}>
                      {getActionText(activity.action)}
                    </span>
                  </div>
                  <p className="text-lg font-semibold text-gray-900 mt-2">
                    {title}
                  </p>
                  <div className="flex justify-between items-center mt-2 text-xs text-gray-600">
                    <div className="flex items-center">
                      <FaUser className="mr-1" />
                      <span>{activity.userName || 'Usuário desconhecido'}</span>
                    </div>
                    <span>{formatDate(activity.timestamp)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* Pop-up */}
        <AnimatePresence>
          {selectedActivity && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
              onClick={closePopup}
            >
              <motion.div
                initial={{ y: 50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                className="bg-white rounded-lg p-6 max-w-lg w-full max-h-[90vh] overflow-y-auto"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 className="text-xl font-bold text-gray-900">Detalhes da Atividade</h3>
                  <button onClick={closePopup} className="text-gray-400 hover:text-gray-600">
                    <FaTimes />
                  </button>
                </div>
                <div className="space-y-4">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      {getActivityIcon(selectedActivity.type)}
                      <span className="ml-2 text-sm font-medium text-gray-600">
                        {getActivityTypeText(selectedActivity.type)}
                      </span>
                    </div>
                    <span className={`text-sm font-bold px-2 py-1 rounded ${
                      selectedActivity.action === 'add' ? 'bg-green-200 text-green-800' :
                      selectedActivity.action === 'edit' ? 'bg-blue-200 text-blue-800' :
                      'bg-red-200 text-red-800'
                    }`}>
                      {getActionText(selectedActivity.action)}
                    </span>
                  </div>
                  <p className="text-lg font-semibold text-gray-900">
                    {decodeUnicode(selectedActivity.description.split(':').slice(1).join(':').trim())}
                  </p>
                  <div className="flex justify-between items-center text-sm text-gray-600">
                    <div className="flex items-center">
                      <FaUser className="mr-1" />
                      <span>{selectedActivity.userName || 'Usuário desconhecido'}</span>
                    </div>
                    <span>{formatDate(selectedActivity.timestamp)}</span>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Home;