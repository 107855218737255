import React, { useState } from 'react';
import { FaPlus, FaSearch, FaEdit, FaTrash, FaUser, FaEnvelope, FaPhone, FaTimes, FaSave } from 'react-icons/fa';
import { useSearch } from '../../context/SearchContext';

const Contacts = ({ contacts, addContact, editContact, deleteContact }) => {
  const { searchQuery, setSearchQuery } = useSearch();
  const [showAddContact, setShowAddContact] = useState(false);
  const [newContact, setNewContact] = useState({ name: '', emails: [''], phones: [''] });
  const [editingContact, setEditingContact] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [errors, setErrors] = useState({});

  const filteredContacts = contacts.filter(contact =>
    contact.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    contact.emails.some(email => email.toLowerCase().includes(searchQuery.toLowerCase())) ||
    contact.phones.some(phone => phone.includes(searchQuery))
  );

  const handleAddContact = () => {
    const newErrors = {};
    if (!newContact.name.trim()) newErrors.name = "Nome é obrigatório";
    if (!newContact.emails[0].trim()) newErrors.email = "Pelo menos um email é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    addContact(newContact);
    setNewContact({ name: '', emails: [''], phones: [''] });
    setShowAddContact(false);
    setErrors({});
  };

  const handleEditClick = (contact) => {
    setEditingContact({ ...contact });
  };

  const handleEditFieldChange = (field, value, index = null) => {
    if (index !== null) {
      const updatedField = [...editingContact[field]];
      updatedField[index] = value;
      setEditingContact({ ...editingContact, [field]: updatedField });
    } else {
      setEditingContact({ ...editingContact, [field]: value });
    }
  };

  const handleAddEditField = (field) => {
    setEditingContact({
      ...editingContact,
      [field]: [...editingContact[field], '']
    });
  };

  const handleRemoveEditField = (field, index) => {
    setEditingContact({
      ...editingContact,
      [field]: editingContact[field].filter((_, i) => i !== index)
    });
  };

  const handleSaveEdit = () => {
    const newErrors = {};
    if (!editingContact.name.trim()) newErrors.name = "Nome é obrigatório";
    if (!editingContact.emails[0].trim()) newErrors.email = "Pelo menos um email é obrigatório";
    
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    editContact(editingContact.id, editingContact);
    setEditingContact(null);
    setErrors({});
  };

  const handleDeleteContact = (id, name) => {
    setConfirmDelete({ id, name });
  };

  const confirmDeleteContact = () => {
    if (confirmDelete) {
      deleteContact(confirmDelete.id);
      setConfirmDelete(null);
    }
  };

  const addField = (field) => {
    setNewContact({
      ...newContact,
      [field]: [...newContact[field], '']
    });
  };

  const removeField = (field, index) => {
    setNewContact({
      ...newContact,
      [field]: newContact[field].filter((_, i) => i !== index)
    });
  };

  const handleFieldChange = (field, index, value) => {
    const updatedFields = [...newContact[field]];
    updatedFields[index] = value;
    setNewContact({
      ...newContact,
      [field]: updatedFields
    });
  };

  const clearSearch = () => {
    setSearchQuery('');
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 mb-8 flex-grow flex flex-col">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold text-gray-700">Contactos</h2>
        <button 
          onClick={() => setShowAddContact(!showAddContact)}
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 flex items-center"
        >
          <FaPlus className="mr-2" />
          {showAddContact ? 'Cancelar' : 'Adicionar'}
        </button>
      </div>

      {showAddContact && (
        <div className="p-6 bg-white border-b border-gray-200">
          <h3 className="text-lg font-medium text-gray-700 mb-4">Novo Contato</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="contact-name" className="block text-sm font-medium text-gray-700 mb-1">
                Nome <span className="text-red-500">*</span>
              </label>
              <input
                id="contact-name"
                type="text"
                value={newContact.name}
                onChange={(e) => setNewContact({ ...newContact, name: e.target.value })}
                placeholder="Digite o nome do contato"
                className={`w-full p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500`}
                required
              />
              {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
            </div>
            {newContact.emails.map((email, index) => (
              <div key={`email-${index}`}>
                <label htmlFor={`email-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                  Email {index + 1}
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    id={`email-${index}`}
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => handleFieldChange('emails', index, e.target.value)}
                    className="flex-grow p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {index === newContact.emails.length - 1 ? (
                    <button onClick={() => addField('emails')} className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300">
                      <FaPlus />
                    </button>
                  ) : (
                    <button onClick={() => removeField('emails', index)} className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300">
                      <FaTimes />
                    </button>
                  )}
                </div>
              </div>
            ))}
            {errors.email && <p className="text-red-500 text-xs mt-1">{errors.email}</p>}
            {newContact.phones.map((phone, index) => (
              <div key={`phone-${index}`}>
                <label htmlFor={`phone-${index}`} className="block text-sm font-medium text-gray-700 mb-1">
                  Telefone {index + 1}
                </label>
                <div className="flex items-center space-x-2">
                  <input
                    id={`phone-${index}`}
                    type="tel"
                    placeholder="Telefone"
                    value={phone}
                    onChange={(e) => handleFieldChange('phones', index, e.target.value)}
                    className="flex-grow p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  {index === newContact.phones.length - 1 ? (
                    <button onClick={() => addField('phones')} className="p-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300">
                      <FaPlus />
                    </button>
                  ) : (
                    <button onClick={() => removeField('phones', index)} className="p-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition duration-300">
                      <FaTimes />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <button 
            onClick={handleAddContact}
            className="mt-6 w-full bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300"
          >
            Adicionar Contato
          </button>
        </div>
      )}

      <div className="mb-4">
        <div className="relative">
          <input
            type="text"
            placeholder="Pesquisar contatos..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full p-2 pl-8 pr-8 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          {searchQuery && (
            <button
              onClick={clearSearch}
              className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
            >
              <FaTimes />
            </button>
          )}
        </div>
      </div>

      <div className="overflow-x-auto flex-grow">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Nome</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Email</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Telefone</th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Ações</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {filteredContacts.map((contact) => (
              <tr key={contact.id} className="hover:bg-gray-50">
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <FaUser className="text-gray-400 mr-3" />
                    <div className="text-sm font-medium text-gray-900">{contact.name}</div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex flex-col">
                    {contact.emails.map((email, index) => (
                      <div key={index} className="flex items-center mb-1">
                        <FaEnvelope className="text-gray-400 mr-2" />
                        <div className="text-sm text-gray-500">{email}</div>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <div className="flex flex-col">
                    {contact.phones.map((phone, index) => (
                      <div key={index} className="flex items-center mb-1">
                        <FaPhone className="text-gray-400 mr-2" />
                        <div className="text-sm text-gray-500">{phone}</div>
                      </div>
                    ))}
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                  <div className="flex space-x-2">
                    <button 
                      onClick={() => handleEditClick(contact)}
                      className="text-yellow-500 hover:text-yellow-700 p-2"
                      title="Editar"
                    >
                      <FaEdit />
                    </button>
                    <button 
                      onClick={() => handleDeleteContact(contact.id, contact.name)}
                      className="text-red-500 hover:text-red-700 p-2"
                      title="Excluir"
                    >
                      <FaTrash />
                    </button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {editingContact && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-8 rounded-lg shadow-xl w-full max-w-md">
            <h3 className="text-xl font-bold mb-4">Editar Contato</h3>
            <div className="space-y-4">
              <input
                type="text"
                value={editingContact.name}
                onChange={(e) => handleEditFieldChange('name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                placeholder="Nome"
              />
              {editingContact.emails.map((email, index) => (
                <div key={`email-${index}`} className="flex items-center space-x-2">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => handleEditFieldChange('emails', e.target.value, index)}
                    className="flex-grow p-2 border border-gray-300 rounded-md"
                    placeholder="Email"
                  />
                  {index === editingContact.emails.length - 1 ? (
                    <button onClick={() => handleAddEditField('emails')} className="p-2 bg-green-500 text-white rounded-md">
                      <FaPlus />
                    </button>
                  ) : (
                    <button onClick={() => handleRemoveEditField('emails', index)} className="p-2 bg-red-500 text-white rounded-md">
                      <FaTimes />
                    </button>
                  )}
                </div>
              ))}
              {editingContact.phones.map((phone, index) => (
                <div key={`phone-${index}`} className="flex items-center space-x-2">
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => handleEditFieldChange('phones', e.target.value, index)}
                    className="flex-grow p-2 border border-gray-300 rounded-md"
                    placeholder="Telefone"
                  />
                  {index === editingContact.phones.length - 1 ? (
                    <button onClick={() => handleAddEditField('phones')} className="p-2 bg-green-500 text-white rounded-md">
                      <FaPlus />
                    </button>
                  ) : (
                    <button onClick={() => handleRemoveEditField('phones', index)} className="p-2 bg-red-500 text-white rounded-md">
                      <FaTimes />
                    </button>
                  )}
                </div>
              ))}
            </div>
            <div className="flex justify-end mt-6 space-x-2">
              <button
                onClick={() => setEditingContact(null)}
                className="px-4 py-2 bg-gray-300 text-black rounded-md hover:bg-gray-400"
              >
                Cancelar
              </button>
              <button
                onClick={handleSaveEdit}
                className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
              >
                <FaSave className="inline-block mr-2" />
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}

      {confirmDelete && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl max-w-sm w-full mx-4">
            <h3 className="text-lg font-bold mb-4">Confirmar exclusão</h3>
            <p className="mb-4">Tem certeza de que deseja excluir o contato "{confirmDelete.name}"?</p>
            <div className="flex flex-col sm:flex-row justify-end space-y-2 sm:space-y-0 sm:space-x-2">
              <button
                onClick={() => setConfirmDelete(null)}
                className="bg-gray-300 hover:bg-gray-400 text-black font-bold py-2 px-4 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={confirmDeleteContact}
                className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Contacts;